import { SubscriptionGAEvent } from 'data-layer';

import { ConversationSubType } from 'app/src/ts/support-claims';

export enum SubscriptionPlan {
  Starter = 'starter',
  Launch = 'launch',
  Grow = 'grow',
  Conquer = 'conquer',
  Partner = 'partner',
}

export type SubscriptionFeatures = {
  warehouses: number;
  uniqueWarehouseCountries: number | 'unlimited';
  customEmails: boolean;
  missionControl: 'none' | 'basic' | 'advanced';
  trackingNotifications: boolean;
  bulkPrinting: boolean;
  numberAvailableIntegration: number;
  syncAmazonEvery10Minutes: boolean;
  numberCustomCouriers: number;
  canCreateManyIntegrationPerCMS: boolean;
  canSeePickingList: boolean;
  profitThreshold: number;
  internalCourier: boolean;
  skipAnalytics: boolean;
  directPrinting: boolean;
  productionList: boolean;
  customerPortal: {
    available: boolean;
    banner: boolean;
    customisable: boolean;
    languages: number;
    watermark: boolean;
    customTexts: boolean;
  };
  customizeReturns: boolean;
  mailSettings: {
    onlyDefaultTemplate: boolean;
    storeLogo: boolean;
    editable: boolean;
    languages: number;
  };
  reverseSurcharge: {
    domestic: number;
    international: number;
  };
  customDomains: boolean;
  showTrackingNotifications: boolean;
  shippingRules: number;
  CODFile: boolean;
  multiUsers: boolean;
  extraLanguages: boolean;
  useTrackingMode: boolean;
  multibranding: boolean;
  ordersExport: boolean;
  useOrderWebhook: boolean;
  disallowedEmails: string[];
  courierDirectMessaging: boolean; // client can send messages to custom courier directly?
  disallowedClaimsSubTypes: ConversationSubType[];
  multiCouriersAllowed: boolean;
  uploadingXLS: boolean;
  zendesk: boolean;
  customCouriersMessaging: boolean; // for receiving DNS records (custom claims)
  customDomainEmails: boolean; // for sending DNS records
  useMapInIntegration: boolean;
  addressBookSearch: boolean;
  pickingAnalytics: boolean;
  connect3pl: boolean;
  pickingWithBaskets: boolean;
  katana: boolean;
  pulpo?: boolean;
  isPayable: boolean;
  partnerShipPage: boolean;
  returnPortal: boolean;
  exchangeByCMS: boolean;
};

export interface SubscriptionPlanDescriptor {
  currency: 'EUR';
  discountedPrice: number;
  name: SubscriptionPlan;
  period: string;
  price: number;
  yearlyPrice: number;
  type: number;
  features: SubscriptionFeatures;
}

export enum TrialActions {
  WATCH_VIDEO = 'watchVideo',
  INTEGRATED_CMS = 'integratedCMS',
  ADDED_COURIER = 'addedCourier',
  SET_UP_BRANDING = 'setUpBranding',
  BRANDED_EMAILS = 'brandedEmails',
}

interface UsedExtendTrialActions {
  [TrialActions.WATCH_VIDEO]?: string;
  [TrialActions.INTEGRATED_CMS]?: string;
  [TrialActions.ADDED_COURIER]?: string;
  [TrialActions.SET_UP_BRANDING]?: string;
  [TrialActions.BRANDED_EMAILS]?: string;
}

export interface SubAddon {
  _id: string;
  type: string;
  limit: number;
  used: number;
  expiredOn: string | null; // date
  deactivatedOn: string; //date
  options: {
    price?: number;
    labelFee?: number;
    fee?: number;
    rebillWhatsAppDate?: string;
    customDeskWhatsAppPrice?: number | null;
  };
}

export interface Subscription {
  _id: string;
  store: string;
  plan: SubscriptionPlan;
  nextPlan?: null | SubscriptionPlan;
  prevPlan?: null | SubscriptionPlan;
  period: 'monthly' | 'yearly';
  nextRebill?: null | string; // date
  startDay?: number;
  amount?: number;
  nextAmount?: null | number;
  prevAmount?: null | number;
  currency: 'EUR' | 'USD';
  errorCount?: number;
  isActive: boolean;
  isPicked: boolean;
  discountActiveFor: number;
  endPromo?: null | string; // date
  rebillFailedOn: null | string; // date
  shopifyBilling: boolean;
  confirmationUrl?: string | null;
  usedExtendTrialActions?: UsedExtendTrialActions;
  tookDowngradeOffer: boolean;
  cancelSubscription: boolean;
  annual: boolean; // 1122 prepaid sub
  addons: SubAddon[];
  gaEvent: SubscriptionGAEvent;
}
